import { QueryClient } from "@tanstack/react-query";
import { Equal } from "effect";

import { fail } from "@ender/shared/utils/error";

const queryClient = new QueryClient({
  defaultOptions: {
    /**
     * TODO ENDER-20884 2024-08-05 Geoffrey: registering QueryClient mutations onError to call fail
     * means when json.warnings are returned from the server, they will be shown as notifications
     * (see shared-utils-error > fail > json.warnings > showWarningNotification)
     * which means we'll get duplicate notifications for the same warning when trying to use both mutations and handleFetchWithWarnings.
     */
    mutations: {
      onError: fail as never,
    },
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      /**
       * If we identify performance bottlenecks with queries as a result of
       * this sharing function
       * (which always fully replaces with new data if any changes are detected)
       * we need to think about how we can preserve Structural Sharing (partial object updates)
       * without stripping away Object Prototype attributes. Effect may have a Struct util for this, but I was
       * unable to find one in a shallow search through their documentation
       *
       * Feb 27 2025
       */
      structuralSharing: (oldData, newData) => {
        if (Equal.equals(oldData, newData)) {
          return oldData;
        }
        return newData;
      },
    },
  },
});

export { queryClient };
