"use client";

import { Cookies, HttpClient } from "@effect/platform";
import { Effect, Function as F, Layer, ManagedRuntime, Option as O } from "effect";
import type { PropsWithChildren } from "react";
import { useState } from "react";
import type { ClientConfigServiceShape } from "@ender/shared/services/config";
import { makeClientConfigService } from "@ender/shared/services/config";
import { RestServiceLive } from "@ender/shared/services/rest";
import { effectRuntimeStore, setRuntime } from "@ender/shared/stores/effect-runtime-store";
function isServer() {
  return F.pipe(globalThis.location, O.fromNullable, O.isNone);
}
function hasEffectRuntime() {
  return F.pipe(effectRuntimeStore.getState(), ({
    runtime
  }) => runtime, O.isSome);
}
function getConfig(): Effect.Effect<ClientConfigServiceShape> {
  // Url must end with a "/" for relative url parsing to work correctly
  const baseApiUrl = new URL("/api/", globalThis.location.origin);
  return Effect.succeed({
    apiCookies: () => Cookies.empty,
    baseApiUrl: () => baseApiUrl
  });
}
function initializeBrowserEffectRuntime() {
  const BrowserLive = RestServiceLive.pipe(Layer.provide(HttpClient.layer), Layer.provide(makeClientConfigService({
    getConfig
  })));
  const browserRuntime = ManagedRuntime.make(BrowserLive);
  setRuntime(browserRuntime);
}
type EffectRuntimeBrowserInitializerProps = {};
function EffectRuntimeBrowserInitializer({
  children
}: PropsWithChildren<EffectRuntimeBrowserInitializerProps>) {
  // Using `useState` ensures this is only run once
  useState(() => {
    if (isServer()) {
      // @ts-expect-error Next.js splits the modules into client & server bundles so during server renders there are
      //   two instances of the effectRuntimeStore and this ensures they store a reference to the same effectRuntime
      // see: EffectRuntimeServerInitializer
      setRuntime(globalThis.effectRuntime);
      return;
    }
    if (hasEffectRuntime()) {
      return;
    }
    initializeBrowserEffectRuntime();
  });
  return children;
}
export { EffectRuntimeBrowserInitializer, initializeBrowserEffectRuntime };