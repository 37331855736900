"use client";

import { QueryClientProvider } from "@tanstack/react-query";
import { useSearchParams } from "next/navigation";
import type { PropsWithChildren } from "react";
import { AuthActorProvider } from "@ender/features/auth";
import { EffectRuntimeBrowserInitializer } from "@ender/shared/initializers/effect-runtime-browser-initializer";
import { queryClient } from "@ender/shared/utils/query-client";
type NextJSBrowserInitializerProps = PropsWithChildren<{}>;
function NextJSBrowserInitializer(props: NextJSBrowserInitializerProps) {
  const {
    children
  } = props;
  const searchParams = useSearchParams();
  return <EffectRuntimeBrowserInitializer data-sentry-element="EffectRuntimeBrowserInitializer" data-sentry-component="NextJSBrowserInitializer" data-sentry-source-file="next-js-browser-initializer.tsx">
      <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="next-js-browser-initializer.tsx">
        <AuthActorProvider token={searchParams.get("token")} data-sentry-element="AuthActorProvider" data-sentry-source-file="next-js-browser-initializer.tsx">
          {children}
        </AuthActorProvider>
      </QueryClientProvider>
    </EffectRuntimeBrowserInitializer>;
}
export { NextJSBrowserInitializer };